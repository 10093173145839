
const ckEditorConfiguration = {
    toolbar: [
        'heading',
        '|',
        'alignment',
        'fontSize',
        'fontColor',
        'bold',
        'italic',
        'subscript',
        'superscript',
        'underline',
        'horizontalLine',
        'link',
        '|',
        'outdent',
        'indent',
        'numberedList',
        'bulletedList',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo'
    ],
    image: {
        toolbar: [
            'imageTextAlternative',
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side',
            'toggleImageCaption'
        ]
    },
    table: {
        contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableCellProperties',
            'tableProperties'
        ]
    },

};

export default ckEditorConfiguration;