import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import CustomCK from "ckeditor5-custom-build/build/ckeditor";
import ckEditorConfiguration from "../../utilities/ckEditorFeatures";
import { useDocumentData } from "react-firebase-hooks/firestore";
import fireApp, { blogsCollectionRef } from "../../firebase/fire-app";
import SectionLoading from "../../Components/SectionLoading";
import { Button } from "react-bootstrap";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { useForm } from "react-hook-form";
import { MdOutlineDelete } from "react-icons/md";

const AdminEditBlog = () => {
    const { register, handleSubmit, watch, errors } = useForm();
    const [saveRequired, setSaveRequired] = useState(false);

    const { id } = useParams();
    const [fetchBlog, loading, error] = useDocumentData(
        blogsCollectionRef.doc(id)
    );
    const [blogData, setBlogData] = useState(false);

    useEffect(() => {
        if (fetchBlog) {
            setBlogData(fetchBlog);
            // alert(JSON.stringify(fetchBlog))
        }
    }, [fetchBlog]);
    const saveBlog = () => {
        blogsCollectionRef
            .doc(id)
            .set({ ...blogData }, { merge: true })
            .then((res) => {
                setBlogData({ ...blogData });
                setSaveRequired(false);
            });
    };

    const onTagAdded = (data, e) => {
        setSaveRequired(true);
        const newTag = data.tag;
        let allTags = [...blogData.tags, newTag];
        setBlogData({ ...blogData, tags: allTags });
        e.target.reset();
    };
    const onFeaturedImgChange = (e) => {
        setSaveRequired(true);
        let storageRef = fireApp.storage().ref();
        let blogStorage = storageRef.child(`blogs/${blogData.id}/${id}`);
        const imgFile = e.target.files[0];
        blogStorage.put(imgFile).then((snapshot) => {
            snapshot.ref.getDownloadURL().then((res) => {
                setBlogData({ ...blogData, featuredImage: res });
            });
        });
    };
    const onTitleChange = (e) => {
        setSaveRequired(true);
        const newTitle = e.target.value;
        setBlogData({ ...blogData, title: newTitle });
    };

    const onDeleteKeyword = (item) => {
        setSaveRequired(true);
        let allTags = blogData.tags.filter((tag) => tag !== item);
        setBlogData({ ...blogData, tags: allTags });
    };

    return (
        <>
            <SidebarComponent>
                <section className="page-heading ">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-between">
                            <h5 className="mb-0">{blogData?.title}</h5>
                            <div></div>
                        </div>
                    </div>
                </section>
                <section className="pt-3 mb-2 bg-lig">
                    <div className="container-fluid">
                        <div className="row">
                            <InputWrapper
                                label={"Title"}
                                className="m"
                                col="10"
                            >
                                <input
                                    type="text"
                                    onChange={onTitleChange}
                                    className="form-control"
                                    placeholder="Blog Title"
                                    defaultValue={blogData?.title}
                                />
                            </InputWrapper>
                        </div>
                    </div>
                </section>
                {blogData ? (
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <CKEditor
                                        editor={CustomCK}
                                        config={ckEditorConfiguration}
                                        data={blogData.content}
                                        onReady={(editor) => {
                                            editor.plugins.get(
                                                "FileRepository"
                                            ).createUploadAdapter = (
                                                loader
                                            ) => {
                                                return new MyUploadAdapter(
                                                    loader,
                                                    blogData.id
                                                );
                                            };
                                        }}
                                        onInit={(editor) => {
                                            editor.plugins.get(
                                                "FileRepository"
                                            ).createUploadAdapter = (
                                                loader
                                            ) => {
                                                return new MyUploadAdapter(
                                                    loader
                                                );
                                            };
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setBlogData({
                                                ...blogData,
                                                content: data,
                                            });
                                        }}
                                        onBlur={(event, editor) => {
                                            console.log("Blur.", editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            console.log("Focus.", editor);
                                        }}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <div className="sticky-top-sidebar-blog">
                                        <div className="editor-sidebar sticky-top py-4">
                                            {/* <h5 className="text-center">Status</h5>
                                <p className="text-center" >{blog.status ? blog.status : "not selected"}</p>
                                <select onChange={onStatusChanged} name="category" className="custom-select">
                                    <option value="Draft">Set Status</option>
                                    <option value="Draft">Draft</option>
                                    <option value="Published">Published</option>
                                    <option value="Restricted">Restricted</option>
                                </select> */}
                                            {saveRequired && (
                                                <p className="mt-3 font-weight-bold text-center text-danger font-italic font-14 font-bold">
                                                    You have made changes to
                                                    this blog. Please click
                                                    "Save" before leaving this
                                                    page.
                                                </p>
                                            )}
                                            <div className="text-center">
                                                <Button
                                                    variant="primary"
                                                    className="btn-sm"
                                                    onClick={saveBlog}
                                                    size="lg"
                                                >
                                                    &nbsp;&nbsp; Save
                                                    &nbsp;&nbsp;{" "}
                                                </Button>
                                            </div>
                                        </div>
                                        <div className="editor-sidebar py-4">
                                            <h5 className="text-center">
                                                Featured Image
                                            </h5>
                                            <div className="p-2 text-center">
                                                {blogData.featuredImage ? (
                                                    <img
                                                        className="img-fluid"
                                                        src={
                                                            blogData.featuredImage
                                                        }
                                                        alt={blogData.slug}
                                                    />
                                                ) : (
                                                    "*no featured image yet"
                                                )}
                                                <input
                                                    type="file"
                                                    onChange={
                                                        onFeaturedImgChange
                                                    }
                                                    name="imageUpload"
                                                    className="mt-3"
                                                />
                                            </div>
                                        </div>

                                        <div className="editor-sidebar py-4">
                                            <h5 className="text-center">
                                                Keywords
                                            </h5>
                                            {blogData.tags &&
                                                blogData.tags.map((item) => (
                                                    <span className="btn btn-sm btn-primary me-2 mb-2">
                                                        {item}{" "}
                                                        <span
                                                            onClick={() =>
                                                                onDeleteKeyword(
                                                                    item
                                                                )
                                                            }
                                                            className="font-16"
                                                        >
                                                            {" "}
                                                            <MdOutlineDelete />
                                                        </span>{" "}
                                                    </span>
                                                ))}
                                            <form
                                                className="mt-2"
                                                onSubmit={handleSubmit(
                                                    onTagAdded
                                                )}
                                            >
                                                <div>
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-8 p-0">
                                                            <input
                                                                placeholder="add new keyword..."
                                                                {...register(
                                                                    "tag"
                                                                )}
                                                                type="text"
                                                                name="tag"
                                                                className="form-control"
                                                            />
                                                        </div>
                                                        <div className="col-4 p-0 text-center">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-sm btn-primary"
                                                            >
                                                                Add Keyword
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        {/* <div className="editor-sidebar py-4">
                                <h5 className="text-center">Category:  </h5>
                                <p className="text-center" >{blog.category ? blog.category : "no category selected"}</p>
                                <div className="row">
                                    <select onChange={onCategoryChanged} name="category" className="custom-select">
                                        {categories && categories.blogCategories.map(item => <option value={item}>{item} </option>)}

                                    </select>
                                    <AddNewCategory />
                                </div>
                            </div> */}
                                        {/* <div className="editor-sidebar py-4">
                                <h5 className="text-center">Writer Details</h5>
                                <input type="text" name="author" placeholder="author name" className="form-control mb-3" />
                                <input type="text" placeholder="facebook" name="" className="form-control mb-3" />
                                <input type="text" placeholder="linkedIn" name="" className="form-control mb-3" />
                                <input type="text" placeholder="twitter" name="" className="form-control mb-3" />
                                <input type="text" placeholder="instagram" name="" className="form-control mb-3" />
                            </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                ) : (
                    <SectionLoading />
                )}
            </SidebarComponent>
        </>
    );
};

class MyUploadAdapter {
    constructor(loader, blogSlug) {
        this.loader = loader;
        this.blogSlug = blogSlug;
    }

    upload() {
        return this.loader.file.then(
            (file) =>
                new Promise((resolve, reject) => {
                    console.log("log", file.name);
                    let storage = fireApp.storage().ref();
                    let blogRef = storage.child(
                        `blogs/${this.blogSlug}/${file.name}`
                    );
                    blogRef.put(file).then((snapshot) => {
                        snapshot.ref.getDownloadURL().then((res) => {
                            resolve({
                                default: res,
                            });
                        });
                    });
                })
        );
    }
}

export default AdminEditBlog;
